<template>
  <div class="recognition-page-container">
    <div class="red-background-border"></div>
    <div class="recogniton-page-box">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
        @updateNumbers="updateNumbers($event)"
      ></PageButton>
      <audio :src="songUrl" ref="audioBox"></audio>

      <div class="recogniton-page-content layout-red-bgc">
        <!-- 左边图片 left image -->
        <div class="recognition-content-left">
          <img class="santa-claus" :src="backgroundImage" alt="" />
        </div>

        <!-- 右边文字 right words-->
        <div class="recogniton-content-right">
          <div class="recognize_container_content_right_border">
            <div class="recognize_container_content_right_content">
              <div
                class="recognize_container_content_right_content_top volume-button"
                :class="{ 'teacher-hover': isClickAudio }"
              >
                <div @click.stop="activeClick('socket')">
                  <!-- <img
                    :src="volumeImgSrc"
                    alt=""
                    :class="{ isPlaying: clickedVolumePlay == 1 }"
                  /> -->
                  <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
                </div>
              </div>
              <div
                class="redColor"
                v-if="wordObj.pinName != '' && !wordObj.toneContent"
              >
                <p class="pinyin font-pinyin-xlarge">
                  {{ wordObj.pinName }}
                </p>
                <p id="face" class="font-hanzi-xlarge">
                  {{ wordObj.chineseName }}
                </p>
              </div>

              <div
                v-if="wordObj.multiEnglishName"
                class="englishName multiLines"
              >
              <p v-for="(item, index) in wordObj.multiEnglishName" :key="index" class="pinyin"
              :class="[
                    wordObj.hanziClass
                      ? wordObj.hanziClass
                      : 'font-hanzi-xlarge',
                  ]">{{item}}</p>
              </div>
              <div
                v-else-if="wordObj.pinName != '' && !wordObj.toneContent && !wordObj.multiEnglishName"
                class="englishName"
              >
                <p
                  :class="[
                    wordObj.englishClass
                      ? wordObj.englishClass
                      : 'font-hanzi-medium',
                      wordObj.pinName ? '' : 'pinyin'
                  ]"
                >
                  {{ wordObj.englishName }}
                </p>
              </div>
              <div
                v-if="wordObj.pinName == '' && !wordObj.toneContent && !wordObj.multiEnglishName"
                class="englishName"
                :class="{ longPinyin: isLongPinyin }"
              >
                <p
                class="pinyin"
                  :class="[
                    wordObj.englishClass
                      ? wordObj.englishClass
                      : 'font-hanzi-xlarge',
                  ]"
                >
                  {{ wordObj.englishName }}
                </p>
              </div>
              <div
                v-if="wordObj.toneContent && wordObj.toneContent.length > 0"
                class="tone_content"
              >
                <p
                  class="pinyin"
                  :class="[
                    pySize == 'xlarge'
                      ? 'font-hanzi-xlarge'
                      : 'font-hanzi-large',
                  ]"
                  v-for="(item, index) in wordObj.toneContent"
                  :key="index"
                >
                  {{ item }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";

export default {
  components: {
    PageButton,
    AudioPlayer,
  },
  props: {
    wordObj: {
      type: Object,
      require: true,
      default: () => {},
    },
    backgroundImage: {
      type: String,
      require: true,
    },
    isTonePage: {
      type: Boolean,
      default: false,
    },
    pySize: {
      type: String,
      require: false,
      default: "",
    },
    isLongPinyin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audioSocketInfo: {},
      songUrl: null,
      isClickAudio: false,
      clickNum: 0,
      isHover: false,
      // clickedVolumePlay: 0,
      isStartPlaying: null,
      isLastStep: true,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "next",
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true,
          },
        ],
      },
    };
  },
  watch: {
    // 发送
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20002,
          data: { value },
          text: "测试RecognitionPage组件内点击事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickRecognitionPage", () => {
      this.activeClick("socket", true);
    });
    this.$bus.$on("hoverRecognitionPage", (val) => {
      this.isClickAudio = !this.isClickAudio;
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickRecognitionPage");
  },
  methods: {
    syncPlayAudio(arr) {
      function tone1() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[0]);
            audio.play();
            resolve();
          }, 500);
        });
      }

      function tone2() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[1]);
            audio.play();
            resolve();
          }, 1000);
        });
      }
      function tone3() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[2]);
            audio.play();
            resolve();
          }, 1200);
        });
      }

      function tone4() {
        return new Promise((resolve) => {
          setTimeout(() => {
            const audio = new Audio(arr[3]);
            audio.play();
            resolve();
          }, 1300);
        });
      }
      async function allTasks() {
        await tone1();
        await tone2();
        await tone3();
        await tone4();
      }
      allTasks();
    },
    activeClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();

      if (this.isTonePage) {
        console.log("+++++++++++");
        this.syncPlayAudio(this.wordObj.songUrlList);
      } else {
        // this.songUrl = JSON.stringify(this.wordObj.songUrl);
        this.songUrl = this.wordObj.songUrl;
        // console.log(JSON.stringify(this.wordObj));
        // console.log(this.songUrl);
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    updateNumbers(value) {
      this.numBers = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .recogniton-page-box {
    // display: none;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #fff;
    border-radius: 58px;
    z-index: 2;
    position: relative;
    .recogniton-page-content {
      background: #f7f4e0;
      border-radius: 58px;
      position: relative;
      z-index: 3;

      .recognition-content-left {
        width: 50%;
        height: 100%;
        position: absolute;
        img {
          position: absolute;
          right: 0;
          top: 20%;
          width: 80%;
        }
        .santa-claus {
          top: 15%;
        }
      }
      .recogniton-content-right {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        .recognize_container_content_right_border {
          margin-top: 10px;
          margin-left: 10px;
          width: calc(70% - 10px);
          height: calc(60% - 10px);
          position: absolute;
          left: 8%;
          top: 13%;
          background: #224e96;
          border-radius: 40px;
          padding: 20px 10px;
          .recognize_container_content_right_content {
            color: #000;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 40px;
            top: -10px;
            left: -10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            cursor: default;

            .englishName {
              max-width: 70%;
            }
            .longPinyin {
              p {
                font-size: 60px !important;
              }
            }
            .font-hanzi-large {
              font-size: 2.5em;
              margin-bottom: 30px;
            }
            .recognize_container_content_right_content_top {
              width: 50px;
              height: 50px;
              background: #ce4c3f;
              border-radius: 50%;

              &.volume-button {
                cursor: pointer;
              }
              div {
                width: 100%;
                height: 100%;
              }
            }
            .recognize_container_content_right_content_top:hover {
              width: 50px;
              height: 50px;
              background: #b5392c;
              border-radius: 50%;
              img {
                border-radius: 50%;
                background: #b5392c;
                width: 100%;
              }
            }
            .tone_content {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 50%;
              p {
                width: 50%;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
  .redColor {
    color: #ce524c;
  }
  .teacher-hover {
    img {
      background: #b5392c !important;
      border-radius: 50%;
    }
  }
}
</style>
